"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// DONT REMOVE COMMENT
// import ReCAPTCHA from "react-google-recaptcha";

import AppButton from "@components/Forms/Buttons/AppButton";
import PasswordInput from "@components/Forms/PasswordInput";
import TextInput from "@components/Forms/TextInput";
import PageLoading from "@components/Loading/PageLoading";
import { signIn } from "next-auth/react";
import { useRouter, useSearchParams } from "next/navigation";
import { classNames } from "@lib/helpers";
import ActivateAccountModal from "@components/Reactive Account/ActivateAccountModal";
import { getSession } from "next-auth/react";
import { useCookies } from "next-client-cookies";

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export default function CandidateLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [reactivationEmail, setReactivationEmail] = useState("");
  // DONT REMOVE THE COMMENT
  // const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const router = useRouter();
  const searchParams = useSearchParams();
  const cookies = useCookies();
  const callbackUrl = searchParams?.get("callbackUrl") || "/";
  const pendingCv = searchParams?.get("pendingCv");

  useEffect(() => {
    const cvId = searchParams.get("cvId");
    localStorage.setItem("cvId", cvId || "");
  }, [searchParams]);
  
  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true);

    const email = values.email.trim().toLowerCase();
    // DONT REMOVE THE COMMENT
    // if (!recaptchaToken) {
    //   setIsLoading(false);
    //   return toast.error("Please complete the reCAPTCHA");
    // }

    const res: any = await signIn("credentials", {
      email,
      password: values.password,
      redirect: false,
      callbackUrl,
    });


    if (res?.error) {
      setIsLoading(false);
      if (res?.error === "user_not_exist") {
        router.push("/register");
        toast.info("ID doesn't exist. You may try to create an account.");
      } else if (res?.error === "activate_user_account") {
        setReactivationEmail(email);
        setShowActivateModal(true);
      } else {
        toast.error(res.error);
      }
    } else if (pendingCv == "true") {
      router.push('/?pendingCv=true');
    } else {
      router.push(res.url);
      setIsLoading(false);
    }
  };

  // DONT REMOVE THE COMMENT
  // const onRecaptchaChange = (token: string | null) => {
  //   setRecaptchaToken(token);
  // };

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <div className="w-full">
            {isLoading && <PageLoading />}

            <div data-aos="fade-up" data-aos-duration="500" className="w-full">
              <h1 className="text-[#fff] font-semibold text-[28px] text-center mb-[20px]">
                Login
              </h1>

              <div className="md:p-9 p-0 bg-transparent">
                <Form className="space-y-3 lg:space-y-4">
                  <Field name="email">
                    {({ field }: { field: any }) => (
                      <div>
                        <TextInput
                          id="email-address"
                          className="!text-[#fff] !rounded-[12px] mb-[30px] h-[56px] w-full !bg-transparent border-[#FFFFFF66] !placeholder-gray-600"
                          name="email"
                          value={field.value}
                          placeholder="Email"
                          type="email"
                          onChange={field.onChange}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="password">
                    {({ field }: { field: any }) => (
                      <div className="w-full">
                        <PasswordInput
                          className="!text-[#fff] !rounded-[12px] w-full h-[56px] !bg-transparent border-[#FFFFFF66] !placeholder-gray-600"
                          id="password"
                          value={field.value}
                          placeholder="Password"
                          type="password"
                          onChange={field.onChange}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500"
                        />
                        <div className="flex flex-row items-center justify-between mt-[15px]">
                          <label className="cursor-pointer flex flex-row items-center gap-[10px] text-[14px] text-[#FFFFFFCC]">
                            <input
                              type="checkbox"
                              className="cursor-pointer bg-transparent w-[28px] h-[28px] rounded-[6px] border-[#4E4E4D]"
                            />
                            Keep me logged in
                          </label>
                          <Link
                            href="/forgot"
                            className="text-[14px] text-[#fff] font-normal"
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                    )}
                  </Field>

                  {/* DONT REMOVE COMMENT */}
                  {/* <ReCAPTCHA
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                  onChange={onRecaptchaChange}
                /> */}

                  <div className="w-full flex items-center justify-center">
                    <AppButton
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className={classNames(
                        "!rounded-[50px] w-[200px] !h-[45px] mt-[15px] text-lg",
                        !isValid ? "!bg-[#FFFFFF0D] cursor-not-allowed" : ""
                      )}
                    >
                      Login
                    </AppButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Formik>
      {showActivateModal && (
        <ActivateAccountModal
          email={reactivationEmail}
          onClose={() => setShowActivateModal(false)}
        />
      )}
    </>
  );
}
